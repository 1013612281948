<template>
  <div class="">
    <!-- <p>{{ userDetails }}</p> -->
    <b-overlay
      :show="show"
      rounded="sm"
    >

      <template #overlay>
        <div class="d-flex flex-column align-items-center justify-content-center">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
          <p class="mt-1">
            {{ $t('Fetching Data') }}...
          </p>
        </div>

      </template>
      <div class="mt-1 card p-3 b">
        <div
          class="d-flex justify-content-center align-items-end no-gutters"
          style="overflow-y: auto"
        >
          <b-table
            class="saj-text"
            style=""
            :items="table.scoreList"
            :fields="table.fields"
            :foot="table.last"
            label-size="lg"
            bordered
          >
            <template #head(keputusansss)="data">
              <span
                class="saj-title"
                style=""
              >{{ $t(data.label) }}</span>
            </template>
            <template #head(pemberattt)="data">
              <span
                class="saj-title"
                style=""
              >{{ $t(data.label) }}</span>
            </template>
            <template #head(markah)="data">
              <span
                class="saj-title"
                style=""
              >{{ $t(data.label) }}</span>
            </template>
            <template #head(markah_terkumpul)="data">
              <span
                class="saj-title"
                style=""
              >{{ $t(data.label) }}</span>
            </template>
            <template #head(markah_manager)="data">
              <span
                class="saj-title"
                style=""
              >{{ $t(data.label) }}</span>
            </template>
            <template #head(markah_terkumpul_manager)="data">
              <span
                class="saj-title"
                style=""
              >{{ $t(data.label) }}</span>
            </template>

            <template #cell(keputusansss)="data">
              {{ $t(data.item.keputusan) }}
              <div v-if="kra === 0">
                <div v-if="data.index === 0">
                  {{ $t("Overall Assessment") }}
                </div>
              </div>
              <div v-else>
                <div v-if="data.index === 0">
                  {{ $t('Key Result Area') }}
                </div>
              </div>
            </template>
            <template #cell(pemberattt)="data">
              <div v-if="data.index === 5">
                <span v-if="contributionPercent === 0 && contributionPercentTeam === 0">100%</span>
                <span v-else>100%</span>
              </div>
              <span v-else>{{ $t(data.item.pemberat) }}</span>

            </template>
          </b-table>

        </div>

        <div class="col-6 p-0 mt-1">
          <b-card
            no-body
            fluid="lg"
            class="d-flex justify-content-center"
            style="border-radius: 20px;"
          >
            <b-row class="d-flex m-0 mt-1 ml-1 mr-1 p-0 pt-1">
              <b-col
                cols="8"
                class="d-flex justify-content-start saj-title"
              >
                {{ $t('Previous score') }}:
              </b-col>
            </b-row>

            <b-card-body
              class="
              d-block
              justify-content-space-between
              align-items-center
              mt-1
            "
            >
              <b-row class="">
                <b-col
                  xl="6"
                  md="6"
                  class="text-center"
                >
                  <span
                    class="d-flex justify-content-center saj-header"
                    style=""
                  >{{ overall_total2 }}</span>
                  <div>
                    <b-card-text
                      class="saj-title"
                      style="color: #000000"
                    >
                      {{ $t('Self Score') }}
                    </b-card-text>
                  </div>
                </b-col>
                <b-col
                  xl="6"
                  md="6"
                  class="text-center"
                >
                  <span
                    class="d-flex justify-content-center saj-header"
                    style=""
                  >{{ overall_total_manager }}</span>
                  <div>
                    <b-card-text
                      class="saj-title"
                      style="color: #000000"
                    >
                      {{ $t('Manager Score') }}
                    </b-card-text>
                  </div>
                </b-col>
              </b-row></b-card-body>
          </b-card>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  BTable,
  BCardBody,
  BCard,
  BCardText,
  BRow,
  BCol,
  BOverlay,
  BSpinner,
  // BFormSelect,
} from "bootstrap-vue"
import axios from "axios"
// import grade from '@/component/grade-modal.vue'
// import editGrade from '@/views/pages/performance-statistics/edit_grade.vue'

export default {
  components: {
    BTable,
    BCardBody,
    BCard,
    BCardText,
    BRow,
    BCol,
    BOverlay,
    BSpinner,
    // BFormSelect,
  },

  props: {
    userID: {
      type: String,
      default: null,
    },
    performanceID: {
      type: String,
      default: null,
    },
    teamRole: {
      type: Number,
      default: 0,
    },
    gradeset: {
      type: Number,
      default: null,
    },
    subId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      show: true,
      kra: null,
      contribution: null,
      contributionPercent: 0,
      contributionPercentTeam: 0,
      scoreYearSelected: "a",
      scoreYearoptions: [
        { value: "a", text: "2022" },
        { value: "b", text: "2021" },
        { value: "c", text: "2020" },
      ],
      overall_total2: "",
      overall_total_manager: "",
      table: {
        // user score
        p1_kra: 0,
        p1_job_description: 0,
        p2_competency_core: 0,
        p2_competency_behaviour: 0,
        p3_training_development: 0,
        p3_contribution: 0,
        accumulative1: 0,
        accumulative2: 0,
        accumulative3: 0,
        accumulative4: 0,
        overall_total: 0,
        // manager score
        p1_kra_manager: 0,
        p1_job_description_manager: 0,
        p2_competency_core_manager: 0,
        p2_competency_behaviour_manager: 0,
        p3_training_development_manager: 0,
        p3_contribution_manager: 0,
        accumulative1_manager: 0,
        accumulative2_manager: 0,
        accumulative3_manager: 0,
        accumulative4_manager: 0,
        overall_total_manager: 0,
        bordered: true,

        scoreList: [
          {
            keputusan: 'Key Result Area', pemberat: '%', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
          {
            keputusan: 'Core Competencies', pemberat: '%', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
          {
            keputusan: 'Behavioural Competencies', pemberat: '', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
          {
            keputusan: 'Training and Development', pemberat: '', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
          {
            keputusan: 'Internal / Industrial Involvement', pemberat: '', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
          {
            keputusan: 'Summary Score (%)', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
        ],
        fields: [
          {
            key: 'keputusansss', label: 'Result', thClass: 'text-center',
          },
          {
            key: 'pemberattt', label: 'Weightage', thClass: 'text-center', tdClass: 'text-center',
          },
          {
            key: 'markah', label: 'Score', thClass: 'text-center', tdClass: 'text-center',
          },
          {
            key: 'markah_terkumpul', label: 'Cumulative Score', thClass: 'text-center', tdClass: 'text-center',
          },
          {
            key: 'markah_manager', label: 'Manager Score', thClass: 'text-center', tdClass: 'text-center',
          },
          {
            key: 'markah_terkumpul_manager', label: 'Manager Cumulative Score', thClass: 'text-center', tdClass: 'text-center',
          },

        ],

      },
      pemberat: null,
      nonExec: null,

    }
  },

  computed: {
    ...mapGetters(['roles', 'userDetails']),
  },

  mounted() {
    this.getUserSet()
    // if (this.performanceID === 0){
    //   this.getScore()
    // }
    // console.log('roles', this.roles)
    // console.log('details', this.userDetails)
  },
  methods: {
    getUserSet() {
      const params = new URLSearchParams()
      if (this.performanceID !== null){
        params.append('user_id', this.userID)
      }

      axios.get(`${this.$baseUrl}/myperformances/getUserPerformanceSet`, { params }).then(response => {
        this.kra = response.data.data.jd_kra
        this.getWeightage()
      })
    },

    getWeightage() {
      const params = new URLSearchParams()

      // console.log('perf id', this.performanceID)

      if (this.performanceID === null){
        params.append(`subsidiary_id`, this.userDetails.business_unit)
      } else {
        params.append(`subsidiary_id`, this.subId)
      }

      if (this.performanceID === null){
        params.append('role_id', this.userDetails.grade_set_id)
      } else {
        params.append('role_id', this.teamRole)
      }

      const tm = this.userDetails.grade_set_id === 1 || this.userDetails.grade_set_id === 2

      if (this.performanceID === null) {
      // My Performance
        this.nonExec = this.userDetails.grade_set_id === 5 || this.userDetails.grade_set_id === 6
      } else {
      // Team Performance
        this.nonExec = this.gradeset === 5 || this.gradeset === 6
      }

      // console.log('tm', tm)
      // console.log('set', set)

      this.$axios.get(`${this.$baseUrl}/weightage/get_role_percentage`, { params }).then(response => {
        // console.log('res', response)
        this.getScore(this.kra)
        const res = response.data.data
        this.pemberat = {
          KRA: this.nonExec ? `${res.overall_assesment}%` : `${res.key_result_area}%`,
          CoreCompetencies: `${res.core_competency}%`,
          BehaviouralCompetencies: `${res.behavioural_competency}%`,
          TrainingAndDevelopment: tm ? `${res.training + res.self_learning}%` : `${res.training}%`,
          OtherContributions: `${res.internal_industry_involement}%`,
          SummaryScore: '100%',
        }
      })
    },

    getScore(set) {
      // console.log('get score')
      // let currentUserRole = this.roles.selectedRole
      // console.log('curr role', currentUserRole)
      // console.log('per id', this.performanceID)
      if (this.performanceID === null) {
        this.$axios.get(`${this.$baseUrl}/myperformances/overall_performance`).then(response => {
          // console.log('res', response)
          this.overall_total2 = response.data.data.overall_total
          this.overall_total_manager = response.data.data.overall_total_manager
          this.contributionPercent = response.data.data.hasContribution

          const status = response.data.data.internal_industry_status === 'enable'

          let finalKRA = ''
          let finalKRAManager = ''
          if (set === 0){
            finalKRA = response.data.data.p1_job_description
            finalKRAManager = response.data.data.p1_job_description_manager
          } else {
            finalKRA = response.data.data.p1_kra
            finalKRAManager = response.data.data.p1_kra_manager
          }

          const { p2_competency_core } = response.data.data
          const { p2_competency_behaviour } = response.data.data
          const { p3_training_development } = response.data.data
          const { p3_contribution } = response.data.data

          const { accumulative1 } = response.data.data
          const { accumulative2 } = response.data.data
          const { accumulative3 } = response.data.data
          const { accumulative4 } = response.data.data
          const { overall_total } = response.data.data

          const { p2_competency_core_manager } = response.data.data
          const { p2_competency_behaviour_manager } = response.data.data
          const { p3_training_development_manager } = response.data.data
          const { p3_contribution_manager } = response.data.data

          const { accumulative1_manager } = response.data.data
          const { accumulative2_manager } = response.data.data
          const { accumulative3_manager } = response.data.data
          const { accumulative4_manager } = response.data.data
          const { overall_total_manager } = response.data.data

          // let currentRole = ''

          // if (currentUserRole === 2) { // non-exec
          //   if (this.kra === 0) {
          //     currentRole = 'nonExecJD'
          //   } else {
          //     currentRole = 'nonExecKRA'
          //   }
          // } else if (currentUserRole === 3) { // exec
          //   if (this.userDetails.grade_set_id === 3){
          //     currentRole = 'topManagement'
          //   } else {
          //     currentRole = 'exec'
          //   }
          // } else if (currentUserRole === 4) { // top management
          //   currentRole = 'topManagement'
          // }

          // if (currentUserRole === 5 || currentUserRole === 6) { // non-exec
          //   if (this.kra === 0) {
          //     currentRole = 'nonExecJD'
          //   } else {
          //     currentRole = 'nonExecKRA'
          //   }
          // } else if (currentUserRole === 4) { // exec
          //   // if (this.gradeset === 3){
          //   //   currentRole = 'topManagement'
          //   // } else {
          //   currentRole = 'exec'
          //   // }
          // } else if (currentUserRole === 1 || currentUserRole === 2 || currentUserRole === 3) { // top management
          //   currentRole = 'topManagement'
          // }

          // const pemberat = {
          //   nonExecKRA: {
          //     KRA: '60%',
          //     CoreCompetencies: '20%',
          //     BehaviouralCompetencies: '15%',
          //     TrainingAndDevelopment: '5%',
          //     OtherContributions: '10%',
          //     SummaryScore: '100%',
          //   },
          //   nonExecJD: {
          //     KRA: '60%',
          //     CoreCompetencies: '20%',
          //     BehaviouralCompetencies: '15%',
          //     TrainingAndDevelopment: '5%',
          //     OtherContributions: '10%',
          //     SummaryScore: '100%',
          //   },
          //   exec: {
          //     KRA: '60%',
          //     CoreCompetencies: '20%',
          //     BehaviouralCompetencies: '15%',
          //     TrainingAndDevelopment: '5%',
          //     OtherContributions: '10%',
          //     SummaryScore: '100%',
          //   },
          //   topManagement: {
          //     KRA: '50%',
          //     CoreCompetencies: '20%',
          //     BehaviouralCompetencies: '25%',
          //     TrainingAndDevelopment: '5%',
          //     OtherContributions: '10%',
          //     SummaryScore: '100%',
          //   },
          // }

          const newScoreList = [
            {
              keputusan: '', pemberat: this.pemberat.KRA, markah: finalKRA, markah_terkumpul: accumulative1, markah_manager: finalKRAManager, markah_terkumpul_manager: accumulative1_manager,
            },
            {
              keputusan: 'Core Competencies', pemberat: this.pemberat.CoreCompetencies, markah: p2_competency_core, markah_terkumpul: accumulative2, markah_manager: p2_competency_core_manager, markah_terkumpul_manager: accumulative2_manager,
            },
            {
              keputusan: 'Behavioural Competencies', pemberat: this.pemberat.BehaviouralCompetencies, markah: p2_competency_behaviour, markah_terkumpul: accumulative3, markah_manager: p2_competency_behaviour_manager, markah_terkumpul_manager: accumulative3_manager,
            },
            {
              keputusan: 'Training and Development', pemberat: this.pemberat.TrainingAndDevelopment, markah: p3_training_development, markah_terkumpul: accumulative4, markah_manager: p3_training_development_manager, markah_terkumpul_manager: accumulative4_manager,
            },
            // {
            //   keputusan: 'Internal / Industrial Involvement', pemberat: this.pemberat.OtherContributions, markah: p3_contribution, markah_terkumpul: overall_total, markah_manager: p3_contribution_manager, markah_terkumpul_manager: accumulative4_manager,
            // },
            {
              keputusan: 'Summary Score (%)', pemberat: this.pemberat.SummaryScore, markah: overall_total, markah_terkumpul: '0.00', markah_manager: overall_total_manager, markah_terkumpul_manager: '0.00',
            },
          ]

          const contr = {
            keputusan: 'Internal / Industrial Involvement', pemberat: this.pemberat.OtherContributions, markah: p3_contribution, markah_terkumpul: overall_total, markah_manager: p3_contribution_manager, markah_terkumpul_manager: accumulative4_manager,
          }

          if (status){
            // this.table.scoreList = newScoreList.slice(3) + contr + newScoreList[4]
            this.table.scoreList = newScoreList.slice(0, 4).concat(contr).concat(newScoreList.slice(4))
          } else {
            this.table.scoreList = newScoreList
          }

          // console.log('table', this.table.scoreList)

          // eslint-disable-next-line camelcase
          this.contribution = p3_contribution
          this.show = false
        })
          .catch(() => {
            this.show = false
          })
      } else {
        // console.log('team performance', set)
        this.$axios.get(`${this.$baseUrl}/myperformances/overall_performance?cycle_id=${this.performanceID}&user_id=${this.userID}`).then(response => {
          // console.log(response)
          this.overall_total2 = response.data.data.overall_total
          this.overall_total_manager = response.data.data.overall_total_manager
          this.contributionPercentTeam = response.data.data.hasContribution

          const status = response.data.data.internal_industry_status === 'enable'

          let finalKRA = ''
          let finalKRAManager = ''
          if (set === 0){
            // console.log('0')
            finalKRA = response.data.data.p1_job_description
            finalKRAManager = response.data.data.p1_job_description_manager
          } else {
            // console.log('1')
            finalKRA = response.data.data.p1_kra
            finalKRAManager = response.data.data.p1_kra_manager
          }

          const { p2_competency_core } = response.data.data
          const { p2_competency_behaviour } = response.data.data
          const { p3_training_development } = response.data.data
          const { p3_contribution } = response.data.data

          const { accumulative1 } = response.data.data
          const { accumulative2 } = response.data.data
          const { accumulative3 } = response.data.data
          const { accumulative4 } = response.data.data
          const { overall_total } = response.data.data

          const { p2_competency_core_manager } = response.data.data
          const { p2_competency_behaviour_manager } = response.data.data
          const { p3_training_development_manager } = response.data.data
          const { p3_contribution_manager } = response.data.data

          const { accumulative1_manager } = response.data.data
          const { accumulative2_manager } = response.data.data
          const { accumulative3_manager } = response.data.data
          const { accumulative4_manager } = response.data.data
          const { overall_total_manager } = response.data.data

          // let currentRole = ''
          // if (this.teamRole !== 0){
          //   currentUserRole = this.teamRole
          //   console.log('team', this.teamRole)
          // }

          // if (currentUserRole === 5 || currentUserRole === 6) { // non-exec
          //   if (this.kra === 0) {
          //     currentRole = 'nonExecJD'
          //   } else {
          //     currentRole = 'nonExecKRA'
          //   }
          // } else if (currentUserRole === 4) { // exec
          //   // if (this.gradeset === 3){
          //   //   currentRole = 'topManagement'
          //   // } else {
          //   currentRole = 'exec'
          //   // }
          // } else if (currentUserRole === 1 || currentUserRole === 2 || currentUserRole === 3) { // top management
          //   currentRole = 'topManagement'
          // }

          // // console.log('curr', currentUserRole)

          // const pemberat = {
          //   nonExecKRA: {
          //     KRA: '60%',
          //     CoreCompetencies: '20%',
          //     BehaviouralCompetencies: '15%',
          //     TrainingAndDevelopment: '5%',
          //     OtherContributions: '10%',
          //     SummaryScore: '100%',
          //   },
          //   nonExecJD: {
          //     KRA: '60%',
          //     CoreCompetencies: '20%',
          //     BehaviouralCompetencies: '15%',
          //     TrainingAndDevelopment: '5%',
          //     OtherContributions: '10%',
          //     SummaryScore: '100%',
          //   },
          //   exec: {
          //     KRA: '60%',
          //     CoreCompetencies: '20%',
          //     BehaviouralCompetencies: '15%',
          //     TrainingAndDevelopment: '5%',
          //     OtherContributions: '10%',
          //     SummaryScore: '100%',
          //   },
          //   topManagement: {
          //     KRA: '50%',
          //     CoreCompetencies: '20%',
          //     BehaviouralCompetencies: '25%',
          //     TrainingAndDevelopment: '5%',
          //     OtherContributions: '10%',
          //     SummaryScore: '100%',
          //   },
          // }

          const newScoreList = [
            {
              keputusan: '', pemberat: this.pemberat.KRA, markah: finalKRA, markah_terkumpul: accumulative1, markah_manager: finalKRAManager, markah_terkumpul_manager: accumulative1_manager,
            },
            {
              keputusan: 'Core Competencies', pemberat: this.pemberat.CoreCompetencies, markah: p2_competency_core, markah_terkumpul: accumulative2, markah_manager: p2_competency_core_manager, markah_terkumpul_manager: accumulative2_manager,
            },
            {
              keputusan: 'Behavioural Competencies', pemberat: this.pemberat.BehaviouralCompetencies, markah: p2_competency_behaviour, markah_terkumpul: accumulative3, markah_manager: p2_competency_behaviour_manager, markah_terkumpul_manager: accumulative3_manager,
            },
            {
              keputusan: 'Training and Development', pemberat: this.pemberat.TrainingAndDevelopment, markah: p3_training_development, markah_terkumpul: accumulative4, markah_manager: p3_training_development_manager, markah_terkumpul_manager: accumulative4_manager,
            },
            // {
            //   keputusan: 'Internal / Industrial Involvement', pemberat: this.pemberat.OtherContributions, markah: p3_contribution, markah_terkumpul: overall_total, markah_manager: p3_contribution_manager, markah_terkumpul_manager: accumulative4_manager,
            // },
            {
              keputusan: 'Summary Score (%)', pemberat: this.pemberat.SummaryScore, markah: overall_total, markah_terkumpul: '', markah_manager: overall_total_manager, markah_terkumpul_manager: '',
            },
          ]

          // console.log('new', newScoreList)

          const contr = {
            keputusan: 'Internal / Industrial Involvement', pemberat: this.pemberat.OtherContributions, markah: p3_contribution, markah_terkumpul: overall_total, markah_manager: p3_contribution_manager, markah_terkumpul_manager: accumulative4_manager,
          }

          if (status){
            // this.table.scoreList = newScoreList.slice(3) + contr + newScoreList[4]
            this.table.scoreList = newScoreList.slice(0, 4).concat(contr).concat(newScoreList.slice(4))
          } else {
            this.table.scoreList = newScoreList
          }

          // this.table.scoreList = newScoreList
          // console.log("checkscore", this.table.scoreList)

          // eslint-disable-next-line camelcase
          this.contribution = p3_contribution
          this.show = false
        })
          .catch(() => {
            this.show = false
          })
      }
    },

  },
}
</script>
<style>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

</style>
